import styled from "@emotion/styled"
import React from "react"
import { colors, fonts, device } from "./layout/GlobalStyles"
import { Button } from "./layout/StyledComponents"

const MainContainer = styled.div`
  padding: 36px 0px;
  margin-top: 36px;
  background: ${colors.secondary203};
  h4 {
    font-size: 60px;
    font-weight: 800;
    letter-spacing: -1.88px;
    line-height: 54px;
    margin: 0;
  }

  @media ${device.tablet} {
    h4 {
      line-height: 65px;
    }
  }
  @media ${device.laptop} {
    padding: 54px 0px 10px;
    h4 {
      margin-bottom: 36px;
    }
  }
`

const Events = styled.div`
  border-top: 2px solid ${colors.primary2};
  padding-top: 34px;
  @media ${device.tablet} {
    display: flex;
  }
`

const Event = styled.div`
  margin-top: 25px;
  p:first-of-type {
    font-size: 30px;
    font-weight: 900;
    margin: 0px 25px 0px;
    padding: 0;
    font-family: ${fonts.secondary};
    color: ${colors.primary2};
    letter-spacing: -0.94px;
    line-height: 36px;
  }
  p:last-of-type {
    margin: 0;
    padding: 0;
  }
  ul {
    li:first-of-type {
      font-weight: 900;
      color: ${colors.dark3};
    }
    li {
      font-size: 22px;
      font-family: ${fonts.primary};
      margin: 6px 0px;
    }
    margin: 14px 0px 0px 0px;
    padding: 0;
    list-style: none;
  }
  @media ${device.tablet} {
    margin: 28px 0px 0px 0px;
  }
  @media ${device.laptop} {
    margin: 28px 0px 0px 0px;
    ul {
      margin-right: 90px;

      width: 195px;
    }
  }
`

const BottomSection = styled.div`
  p {
    margin: 0;
  }
  button {
    margin: 15px 0px;
  }
  h5 {
    font-size: 22px;
    font-weight: 900;
    color: ${colors.dark3};
    margin: 0;
    font-family: ${fonts.primary};
    letter-spacing: -0.94px;
    line-height: 36px;
  }
  @media ${device.tablet} {
    p {
      font-size: 22px;
    }
    h5 {
      font-size: 30px;
    }
  }
  @media ${device.laptop} {
    h5 {
      margin-bottom: 15px;
      font-size: 30px;
    }
    .button-container {
      display: flex;
      margin-top: 35px;
      align-items: center;
      justify-content: space-between;
      padding-right: 40px;
      button {
        margin-right: 50px;
      }
    }
  }
`

export const ChurchEvents = () => {
  return (
    <MainContainer>
      <div className="container">
        <p
          style={{
            fontWeight: "900",
            color: `${colors.dark3}`,
            fontSize: "22px",
            margin: "-15px 0px 36px",
          }}
        >
          Times are are in both Pacific Time (PT) and East Africa Time (EAT).
        </p>
        <Events>
          <Event>
            <p>
              Prayer <br />
              Watch{" "}
            </p>
            <ul>
              <li>Mondays</li>
              <li>8 - 9 pm (PST)</li>
              <li>7 - 8 am (EAT)</li>
            </ul>
          </Event>
          <Event>
            <p>
              Leadership <br />
              Circle
            </p>
            <ul>
              <li>2nd Saturday</li>
              <li>10 - 11 am (PST)</li>
              <li>9 - 10 pm (EAT)</li>
            </ul>
          </Event>
          <Event>
            <p>
              Sacred <br />
              Circle
            </p>
            <ul>
              <li>Last Tuesday</li>
              <li>10 - 11 am (PST)</li>
              <li>9 - 10 pm (EAT)</li>
            </ul>
          </Event>
          <Event>
            <p>
              Global <br />
              Circle
            </p>
            <ul>
              <li>4th Saturday</li>
              <li>10 - 12 am (PST)</li>
              <li>9 - 10 pm (EAT)</li>
            </ul>
          </Event>
        </Events>
        <BottomSection>
          <div className="button-container">
            <p>
              All Sacred Gatherings are held via a Zoom call until further
              notice. Please email <br />
              us to get the details and specify which gathering you would like
              to attend.
            </p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="mailto:hello@mlifefoundation.org"
            >
              <Button color="yellow">Email Us</Button>
            </a>
          </div>
        </BottomSection>
      </div>
    </MainContainer>
  )
}
