import styled from "@emotion/styled"
import React from "react"
import { colors, device } from "../layout/GlobalStyles"
import { StaticImage } from "gatsby-plugin-image"
import { Button } from "../layout/StyledComponents"
import { PanelContainer } from "./Panel-Container"
import { ChurchEvents } from "../Church-Events"
import { ModalBlock } from "../Modal"
import { modalText } from "../modalText"

const MainContainer = styled.div`
  padding: 72px 0px;
  background: ${colors.secondary203};
  p {
    color: ${colors.dark3};
    letter-spacing: -0.69px;
    line-height: 36px;
    font-size: 22px;
    margin: 0;
  }
  .image {
    margin-bottom: 25px;
  }
  h3 {
    padding: 0;
    font-size: 40px;
    font-weight: 800;
    letter-spacing: -1.25px;
    line-height: 48px;
    margin: 0 30px 15px 0px;
  }
  > section:last-of-type {
    margin-top: 32px;
  }
  button {
    margin-top: 25px;
  }
  @media ${device.tablet} {
    img {
      width: 500px;
    }
  }
  @media ${device.laptop} {
    padding: 20px 0px 116px;
    > section:first-of-type {
      .image {
        position: relative;
        top: 40px;
      }
      img {
        width: 453px;
      }
    }
    > section:last-of-type {
      margin-top: 40px;
      .second-image {
        position: relative;

        bottom: 50px;
      }
      img {
        /* height: 475px; */
        height: 476px;
        width: 100%;
      }
    }
    .first-section {
      width: 543px;
      margin-left: 90px;
    }
    .second-section {
      p {
      }
      width: 557px;
      margin-top: 20px;
    }
  }
`

export const ChurchPanels = () => {
  return (
    <MainContainer>
      <PanelContainer>
        <StaticImage
          className="image"
          src="../../images/church-collage-1.webp"
          placeholder="none"
          quality={100}
          alt="people at a concert"
        />
        <div className="first-section">
          <p>
            Exploring in our everyday experiences what it means to be human and
            joining others in their story of being human. Stories filled, on one
            hand, with tension, despair, and restlessness and on the other, with
            harmony, hope, and renewal.
          </p>
        </div>
      </PanelContainer>
      <PanelContainer reverse>
        <StaticImage
          className="second-image image"
          src="../../images/church-two.webp"
          placeholder="none"
          quality={100}
          alt="jesus on cross"
        />
        <div className="second-section">
          <h3>Jesus is our role model</h3>
          <p>
            <span style={{ fontWeight: "900" }}>
              At our Church, we are content in following Jesus, not worshipping
              him.
            </span>{" "}
            We strive to love as much as Jesus did–without conditions, inviting
            others to partake of his impressive work; his love for <br />
            humanity, his passion for community, and his advocacy for justice.
          </p>
          <ModalBlock modalText={modalText.church.text}>
            <Button color="yellow">Our Tenet of Faith</Button>
          </ModalBlock>
        </div>
      </PanelContainer>
      {/* <ChurchEvents /> */}
    </MainContainer>
  )
}
