import styled from "@emotion/styled"
import React from "react"
import { colors, fonts, device } from "./layout/GlobalStyles"
import { StaticImage } from "gatsby-plugin-image"

const MainContainer = styled.div`
  background: ${colors.secondary201};
  z-index: -2;
`

const StyledPurposeHero = styled.div`
  height: 78vh;
  position: relative;
  padding: 1em;
  padding-top: 72px;
  p {
    background: none;
    color: ${colors.dark3};
  }
  .image-container {
    display: none;
  }
  h1 {
    margin-top: 0px;
    margin-bottom: 0em;
    width: 280px;
    font-size: 36px;
    line-height: 45px;
    font-weight: 300;
    color: ${colors.primary2};
    font-family: ${fonts.secondary};
  }
  p {
    font-family: ${fonts.primary};
    font-size: 22px;
    font-weight: 400;
    letter-spacing: -0.69px;
    line-height: 36px;
    width: 300px;
  }
  @media ${device.tablet} {
    height: 80vh;
    padding-top: 102px;
    position: relative;
    .image-container {
      display: block;
      bottom: 0em;
      right: -40px;
      position: absolute;
      z-index: 0;
      .main-image {
        img {
          height: 451.1px;
          width: 513.3px;
        }
      }
    }
    h1 {
      letter-spacing: -1.88px;
      line-height: 65px;
      font-size: 60px;
      margin-top: 0px;
      padding: 0em;
      width: 629px;
    }
    p {
      width: 600px;
      z-index: 200;
    }
  }
  @media ${device.laptop} {
    padding: 210px 0px 0px;
    position: relative;
    height: 450px;
    .image-container {
      display: block;
      right: 0px;
      position: absolute;
      z-index: 2;
      .main-image {
        img {
          height: 451.1px;
          width: 513.3px;
          /* width: 35vw;
          height: 100%;
          max-width: 640px;
          max-height: 600px; */
        }
      }
    }
    h1 {
      letter-spacing: -1.88px;
      line-height: 65px;
      font-size: 60px;

      padding: 0em;
      width: 569px;
    }
    p {
      width: 537px;
    }
  }
`

export const ChurchHero = () => {
  return (
    <MainContainer>
      <StyledPurposeHero className="container">
        <div className="image-container">
          <StaticImage
            className="main-image"
            src="../images/church/mwangi-with-microphone.png"
            alt="mwangi with a microphone"
            placeholder="none"
            quality={100}
          />
        </div>
        <h1>Welcome home. Our spiritual space for the “other”</h1>
        <p>
          We are home for those who scarcely believe they belong: The loners.
          The rejects. The black sheep. The lost. The forgotten. The outliers.
        </p>
      </StyledPurposeHero>
    </MainContainer>
  )
}
