import React from "react"
import { ChurchHero } from "../components/Church-Hero"
import { ChurchPanels } from "../components/panels/Church-Panels"
import { MissionVision } from "../components/Mission-Vision"
import { InsideOurChurch } from "../components/Inside-Our-Church"
import { AttendSacredGathering } from "../components/Attend-Sacred-Gathering"
import { ScheduleVisit } from "../components/Schedule-Visit"
import { ImageContainer } from "../components/Image-Container"
import { StaticImage } from "gatsby-plugin-image"
import { Giving } from "../components/Giving"
import { EcclesiaPanel } from "../components/Ecclesia-Panel"
import { Seo } from "../components/Seo"

const Chuch = () => {
  return (
    <>
      <Seo
        title="We honor the complexity of every person’s story"
        description="Exploring in our everyday experiences what it means to be human and joining others in their story of being human."
      />{" "}
      <ChurchHero />
      <ChurchPanels />
      <MissionVision />
      <InsideOurChurch />
      <AttendSacredGathering />
      <ScheduleVisit />
      <ImageContainer>
        <StaticImage
          src="../images/church/woman-worshipping.jpeg"
          placeholder="none"
          quality={100}
          alt="woman worshipping"
        />
      </ImageContainer>
      <Giving />
      <EcclesiaPanel />
    </>
  )
}

export default Chuch
