import styled from "@emotion/styled"
import React from "react"
import { fonts, colors, device } from "./layout/GlobalStyles"
import ecclesiaLogo from "../images/programs/logo-ecclesia.svg"
import { Link } from "gatsby"

const MainContainer = styled.div`
  padding: 62px 0px 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${colors.primary1};
  a {
    color: black;
  }
  h4 {
    font-size: 42px;
    margin: 25px 0px 15px;
    font-weight: 800;
    margin-top: 0px;
    letter-spacing: -1.88px;
    line-height: 45px;
  }
  @media ${device.tablet} {
    padding: 128px 0px 120px;
    h4 {
      line-height: 72px;
      font-size: 60px;
      margin-bottom: 5px;
    }
  }
  @media ${device.laptop} {
    padding: 114px 0px 106px;
  }
`

const Panel = styled.div`
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 8px 64px 0 rgba(0, 0, 0, 0.05);
  border: 3px solid #fff;
  padding: 40px 25px;
  margin: 25px 0px;
  transition: box-shadow 0.2s ease-in;
  h4 + p {
    font-size: 30px;
    /* margin: 0em 0px 25px 0px; */
    margin-bottom: 70px;
    font-weight: 900;
    letter-spacing: -0.94px;
    line-height: 36px;
  }

  h4 + p {
    font-size: 24px;
    margin: 0em;
    font-weight: 900;
    letter-spacing: -0.94px;
    line-height: 36px;
  }
  h4,
  p {
    color: ${colors.dark3};
  }
  &:hover {
    border: 3px solid #16675a;
    box-shadow: none;
  }
  h5 {
    margin: 25px 0px 15px 0px;
  }
  p {
    font-weight: 400;
    font-size: 22px;
  }
  p:first-of-type {
    font-weight: 900;
  }
  h5 {
    font-family: ${fonts.secondary};
    color: ${colors.primary2};
    font-size: 30px;
    letter-spacing: -0.94px;
    line-height: 40px;
    span {
      font-weight: 800;
    }
  }
  @media ${device.laptop} {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 34px 42px;
    width: 1012px;
    h4 {
      font-size: 60px;
    }
    p {
      font-size: 22px;
      margin: 0px 0px 10px;
    }
    .image {
      width: 180px;
    }
    .text-container {
      max-width: 628px;
    }
  }
  @media ${device.laptopL} {
    max-width: 1112px;
  }
`

export const EcclesiaPanel = () => {
  return (
    <MainContainer>
      <div className="container">
        <h4>Program</h4>
        <Link to="/programs/ecclesia/">
          <Panel>
            <img className="image" src={ecclesiaLogo} alt="ecclesia logo" />
            <div className="text-container">
              <h5>
                <span>Ecclesia</span>
              </h5>
              <p>Community - All ages & people welcome </p>
              <p>
                We support sabbath rest for high-impact faith leaders. Ecclesia,
                aka MLIFE Church, is a radical spiritual space for those who
                scarcely believe they belong–disenfranchised, discriminated
                against, or locked in poverty to break bread and provide a
                sabbath rest for those needing respite.
              </p>
            </div>
          </Panel>
        </Link>
      </div>
    </MainContainer>
  )
}
