import styled from "@emotion/styled"
import React from "react"
import { fonts, colors, device } from "./layout/GlobalStyles"
import { Button } from "./layout/StyledComponents"
import { StaticImage } from "gatsby-plugin-image"

const MainContainer = styled.div`
  padding: 62px 0px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  h5 {
    font-family: ${fonts.secondary};
    color: ${colors.primary2};
  }
  p {
    font-size: 22px;
    font-weight: 400;
    letter-spacing: -0.69px;
    line-height: 36px;
    color: ${colors.dark3};
  }
  @media ${device.tablet} {
  }
  @media ${device.laptop} {
    padding: 123px 0px 128px;
    align-items: start;
    .flex-container {
      width: 1112px;
      justify-content: space-between;
      display: flex;
      flex-direction: row;
      align-items: start;
    }
  }
`

const Title = styled.div`
  h5 {
    margin: 0;
    font-size: 60px;
    font-weight: 800;
    letter-spacing: -1.88px;
    line-height: 65px;
  }
  p {
    margin: 20px 0px 25px 0px;
  }
  @media ${device.laptop} {
    margin-bottom: 50px;
  }
`

const LeftColumn = styled.div`
  height: 1000px;
  @media ${device.laptop} {
    .sticky {
      position: -webkit-sticky; /* Safari */
      position: sticky;
      top: 80px;
      img {
      }
    }
    div {
      position: relative;
      top: -10px;
    }
    img {
      height: 271px;
      width: 393.26px;
    }
  }
`

const RightColumn = styled.div`
  margin-top: 20px;

  @media ${device.laptop} {
    margin-top: 0px;
    .last-panel {
      margin-bottom: -10px;
    }
    p {
      width: 638px;
    }
  }
`

const Panel = styled.div`
  margin-bottom: 34px;
  p:first-of-type {
    font-size: 30px;
    font-weight: 900;
    letter-spacing: -0.94px;
    line-height: 36px;
    margin: 0;
  }

  p:last-of-type {
    margin-top: 15px;
  }

  button {
    margin: 24px 0px 0px;
  }
  h5 {
    margin: 0;
    font-weight: 800;
    font-size: 32px;
    line-height: 42px;
    letter-spacing: -1.25px;
  }
  p {
    margin: 0;
  }
  @media ${device.tablet} {
    h5 {
      font-size: 40px;
      line-height: 48px;
    }
  }
  @media ${device.laptop} {
    margin-bottom: 64px;
  }
`

export const Giving = () => {
  return (
    <MainContainer className="container">
      <Title>
        <h5>Giving</h5>
        <p>
          There are three ways to support our liturgy, programs, and or work:
          Treasure, Talent, and Time.{" "}
        </p>
      </Title>
      <div className="flex-container">
        <LeftColumn>
          <StaticImage
            className="sticky"
            src="../images/programs/ecclesia/teacup.png"
            placeholder="none"
            quality={100}
            alt="teacup"
          />
        </LeftColumn>
        <RightColumn>
          <Panel>
            <p>Treasure</p>
            <h5>Tithe to our humani-TEA</h5>
            <p>
              We invite you to join our Guardians-of-faith community by giving
              10 percent of your income to restore justice and empower those in
              despair to build solutions with and for their community. Your gift
              equips those closest to the need to make the most significant
              impact.
            </p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://forms.gle/57iAYyquQUuuQNPG6"
            >
              <Button color="yellow">Promise a pledge</Button>
            </a>
          </Panel>
          <Panel>
            <p className="talent">Talent</p>
            <h5>Create possibili-TEAS</h5>
            <p>
              Give us the gift of your expertise by volunteering 10 hours
              monthly in our LEAP and MLIFE Programs. We are always seeking
              experts in the field of technology, education, and arts. We
              require a 1 yr commitment.
            </p>
          </Panel>
          <Panel className="last-panel">
            <p>Time</p>
            <h5>Expand opportuni-TEAS</h5>
            <span></span>
            <p>
              Volunteering with us is a great way to kick-start the
              conversation. We organize 10-day volunteer trips to Kenya in
              January and August to support our communities through workshops
              and training where you can transfer your knowledge to our local
              communities. We also offer volunteer opportunities all year round
              for most of our programs.
            </p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://forms.gle/YjVthzaLAFpMHNtT9"
            >
              <Button color="yellow">Get started</Button>
            </a>
          </Panel>
        </RightColumn>
      </div>
    </MainContainer>
  )
}
