import styled from "@emotion/styled"
import React from "react"
import { fonts, colors, device } from "./layout/GlobalStyles"
import { Button } from "./layout/StyledComponents"
import people from "../images/church/visit-people.svg"

const MainContainer = styled.div`
  padding: 72px 0px;
  display: flex;
  background: ${colors.secondary201};
  flex-direction: column;
  align-items: center;
  p {
    color: ${colors.dark3};
  }
  img {
    margin: 0 auto 25px;
    width: 75%;
  }
  h5 {
    font-size: 40px;
    font-weight: 800;
    font-family: ${fonts.secondary};
    color: ${colors.primary2};
    letter-spacing: -1.56px;
    line-height: 55px;
    margin: 0 0 15px 0;
  }
  p {
    font-weight: 400;
    margin: 0 0 25px 0;
  }
  @media ${device.tablet} {
    .flex-contain {
      display: flex;
      justify-content: space-between;
      width: 650px;
      img {
        position: relative;
        right: 40px;
        width: 240px;
      }
      p {
        width: 300px;
        font-size: 22px;
        letter-spacing: -0.69px;
        line-height: 36px;
      }
    }
  }
  @media ${device.laptop} {
    padding: 114px 0px 109px;
    .flex-contain {
      display: flex;
      justify-content: space-between;
      width: 100%;
      img {
        height: 225px;
        width: 232px;
      }
      p {
        width: 732px;
      }
    }
  }
`

export const ScheduleVisit = () => {
  return (
    <MainContainer>
      <div className="flex-contain container">
        <img src={people} alt="3 people hanging out" />
        <div>
          <h5>Schedule a Visit</h5>
          <p>
            Email our pastoral team if you’d like a meeting or know more about
            our beliefs before attending your first service or review our past
            service
          </p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="mailto:hello@mlife.church"
          >
            <Button color="yellow">Email us</Button>
          </a>
        </div>
      </div>
    </MainContainer>
  )
}
