import styled from "@emotion/styled"
import React from "react"
import { colors, fonts, device } from "./layout/GlobalStyles"
import { Button } from "./layout/StyledComponents"
import { StaticImage } from "gatsby-plugin-image"

const MainContainer = styled.div`
  background: ${colors.secondary203};
  padding: 72px 0px 72px;
  p {
    color: ${colors.dark3};
  }
  .outlier {
    font-weight: 900;
    font-size: 22px;
    margin-top: 20px;
    color: ${colors.dark3};
    letter-spacing: -0.69px;
    line-height: 36px;
    padding-bottom: 30px;
    border-bottom: 1px solid ${colors.dark3};
  }
  @media ${device.laptop} {
    padding: 132px 0px 128px;
    .outlier {
      margin-top: 75px;
    }
  }
`

const TopPanel = styled.section`
  h4 {
    font-size: 40px;
    font-weight: 800;
    letter-spacing: -1.56px;
    line-height: 45px;
    margin: 25px 0px 0px 0px;
    padding: 0;
  }
  p {
    color: ${colors.dark3};
    font-size: 22px;
    font-weight: 400;
    letter-spacing: -0.69px;
    line-height: 36px;
    margin: 0;
    padding: 25px 0px 0px 0px;
  }

  @media ${device.tablet} {
    h4 {
      font-size: 50px;
      margin: 55px 0px 0px 0px;
      font-weight: 800;
      letter-spacing: -1.56px;
      line-height: 55px;
    }
  }
  @media ${device.laptop} {
    display: flex;
    justify-content: space-between;

    img {
      height: 395px;
      width: 541px;
    }

    div:last-of-type {
      width: 434px;
      position: relative;
      bottom: 40px;
      padding-top: 0;
      margin-top: 0;
    }
  }
`

const BottomPanel = styled.div`
  padding-top: 10px;
  @media ${device.laptop} {
    padding-top: 35px;
    display: flex;
  }
`

const Event = styled.div`
  margin-top: 25px;
  p:first-of-type {
    font-weight: 800;
    font-size: 30px;
    letter-spacing: -0.94px;
    line-height: 40px;
    font-family: ${fonts.secondary};
    color: ${colors.primary2};
  }
  p:last-of-type {
    margin: 0;
    padding: 0;
  }
  ul {
    color: ${colors.dark3};
    li:first-of-type {
      font-weight: 900;
    }
    li {
      font-size: 22px;
      font-family: ${fonts.primary};
      margin: 6px 0px;
    }
    margin: 14px 0px 0px 0px;
    padding: 0;
    list-style: none;
  }
  @media ${device.tablet} {
    margin: 28px 0px 0px 0px;
  }
  @media ${device.laptop} {
    margin: 28px 0px 0px 0px;
    ul {
      margin-right: 90px;

      width: 195px;
    }
  }
`

const EmailContainer = styled.div`
  margin-top: 30px;
  p {
    font-size: 22px;
    font-weight: 300;
    letter-spacing: -0.69px;
    line-height: 36px;
  }
  @media ${device.laptop} {
    display: flex;
    align-items: center;
    p {
      font-weight: 400;
      width: 811px;
    }
    button {
      margin-left: 40px;
    }
  }
`

export const AttendSacredGathering = () => {
  return (
    <MainContainer>
      <div className="container">
        <TopPanel>
          <StaticImage
            src="../images/church/people-praying.png"
            placeholder="none"
            quality={100}
            alt="collage of people praying"
          />
          <div>
            <h4>Attend a Sacred Gathering</h4>
            <p>
              At each of our sacred gatherings, we guide you through a
              meditative journey, incorporating elements of spirituality and
              community to catalyze our collective human flourishing.
            </p>
          </div>
        </TopPanel>
        <p className="outlier">
          Times are are in both Pacific Time (PT) and East Africa Time (EAT).
        </p>
      </div>
      <BottomPanel className="container">
        <Event>
          <p>
            Prayer <br />
            Watch{" "}
          </p>
          <ul>
            <li>Mondays</li>
            <li>8 - 9 pm (PST)</li>
            <li>7 - 8 am (EAT)</li>
          </ul>
        </Event>
        <Event>
          <p>
            Leadership <br />
            Circle
          </p>
          <ul>
            <li>2nd Saturday</li>
            <li>10 - 11 am (PST)</li>
            <li>9 - 10 pm (EAT)</li>
          </ul>
        </Event>
        <Event>
          <p>
            Sacred <br />
            Circle
          </p>
          <ul>
            <li>Last Tuesday</li>
            <li>10 - 11 am (PST)</li>
            <li>9 - 10 pm (EAT)</li>
          </ul>
        </Event>
        <Event>
          <p>
            Global <br />
            Circle
          </p>
          <ul>
            <li>4th Saturday</li>
            <li>10 - 12 am (PST)</li>
            <li>9 - 10 pm (EAT)</li>
          </ul>
        </Event>
      </BottomPanel>
      <EmailContainer className="container">
        <p>
          All Sacred Gatherings are held via a Zoom call until further notice.
          Please email us to get the details and specify which gathering you
          would like to attend.
        </p>
        <a
          target="_blank"
          rel="noreferrer"
          href="mailto:hello@mlifefoundation.org"
        >
          <Button color="yellow">Email us</Button>
        </a>
      </EmailContainer>
    </MainContainer>
  )
}
