import styled from "@emotion/styled"
import React from "react"
import { device, colors } from "./layout/GlobalStyles"
import authentic from "../images/church/authentic.svg"
import daring from "../images/church/daring.svg"
import supportive from "../images/church/supportive.svg"
import felicitous from "../images/church/felicitous.svg"

const StyledInsideOurChurch = styled.div`
  padding: 62px 0px 68px;
  h4 {
    margin: 0;
    font-size: 60px;
    font-weight: 800;
    letter-spacing: -1.88px;
    line-height: 65px;
  }
  p {
    color: ${colors.dark3};
    font-size: 22px;
    font-weight: 400;
    letter-spacing: -0.69px;
    line-height: 36px;
    margin: 0;
  }
  div > h4 {
    font-size: 40px;
  }
  img {
    width: 100%;
  }
  @media ${device.laptop} {
    padding: 114px 0px;
    .reverse {
      display: flex;
      flex-direction: row-reverse;
    }
  }
`

const Section = styled.div`
  margin-top: 50px;

  @media ${device.laptop} {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    img {
      width: 543px;
    }
    p {
      width: 448px;
    }
  }
`

export const InsideOurChurch = () => {
  return (
    <StyledInsideOurChurch className="container">
      <h4>Inside our Church</h4>
      <Section className="reverse">
        <img src={daring} alt="animated money going to people" />
        <div>
          <h4>Daring</h4>
          <p>
            We are venturesome - in word, action, and commitment. We follow
            through with our obligations regardless of the costs.
          </p>
        </div>
      </Section>
      <Section>
        <img src={authentic} alt="animation 3 adults and baby" />
        <div>
          <h4>Authentic</h4>
          <p>
            We reveal, not hide, who we are - our character, identities,
            struggles, and triumphs. We are a space for all infinite expressions
            and identities.
          </p>
        </div>
      </Section>
      <Section className="reverse">
        <img src={felicitous} alt="animation 3 adults and baby" />
        <div>
          <h4>Felicitous</h4>
          <p className="felicitous-paragraph">
            We are pleasant and sometimes savory. We like people to have a
            delightsome experience with us.
          </p>
        </div>
      </Section>
      <Section>
        <img src={supportive} alt="animation people cheering for eachother" />
        <div>
          <h4>Supportive</h4>
          <p>
            Our spaces are safe and exclude judgment toward all people
            irrespective of their past, present, or future moral or spiritual
            story.
          </p>
        </div>
      </Section>
    </StyledInsideOurChurch>
  )
}
