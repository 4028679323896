import styled from "@emotion/styled"
import React from "react"
import { colors, device } from "./layout/GlobalStyles"

const MainContainer = styled.div`
  background: ${colors.secondary102};
`

const BlueContainer = styled.div`
  color: ${colors.white};
  padding: 32px 0px 72px;
  p {
    color: ${colors.white};
  }
  display: flex;
  flex-direction: column;

  h4 {
    color: ${colors.secondary203};
    font-weight: 800;
    letter-spacing: -1.88px;
    font-size: 60px;
    line-height: 65px;
  }
  h4:last-of-type {
    margin-top: 25px;
  }
  h4,
  p {
    text-align: left;
  }

  p {
    margin: 0;
    letter-spacing: -0.69px;
    line-height: 36px;
    font-size: 22px;
  }
  h4 {
    margin: 0px 0px 10px 0px;
  }
  @media ${device.tablet} {
    flex-direction: row;
    p {
      width: 310px;
    }
  }
  @media ${device.laptop} {
    padding: 120px 0px 128px;
    flex-direction: row;
    justify-content: space-between;
    h4,
    p {
      padding: 0px 35px;
    }
    h4:last-of-type {
      margin: 0 0 25px 0px;
    }
    h4 {
      margin-bottom: 25px;
      padding: 0;
    }
    p {
      padding: 0;
    }
    p:first-of-type {
      width: 402px;
    }
    .right-column {
      p {
        width: 543px;
      }
    }
  }
`

export const MissionVision = () => {
  return (
    <MainContainer>
      <BlueContainer className="container">
        <div>
          <h4>Mission</h4>
          <p>
            Our mission is to build an inclusive faith-based community for the
            historically excluded.
          </p>
        </div>
        <div className="right-column">
          <h4>Vision</h4>
          <p>
            We envision a world where everyone lives as fast or slow and as loud
            or quiet as they could be. Knowing that life is a gift and love is
            the point.
          </p>
        </div>
      </BlueContainer>
    </MainContainer>
  )
}
